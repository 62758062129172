export interface myUploadsParamType {
  keyword: string;
  baseFilter: string;
  activityStatus: string;
  requestStatusFilter: string;
  size: number;
}
export const getMyUploadsParams = (
  keyword: string,
  baseFilter: string,
  activityStatus: string,
  requestStatusFilter: string
) => {
  const params = {} as myUploadsParamType;
  if (keyword) {
    params['keyword'] = keyword;
  }
  if (baseFilter) {
    params['baseFilter'] = baseFilter;
  }
  if (activityStatus) {
    params['activityStatus'] = activityStatus;
  }
  if (requestStatusFilter) {
    params['requestStatusFilter'] = requestStatusFilter;
  }
  params['size'] = 100;

  return params;
};

export interface myChatParamType {
  page: number;
  size: number;
}
export const getMyChatParams = (page: number, size: number) => {
  const params = {} as myChatParamType;
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  return params;
};
export interface myMessageParamType {
  keyword: string;
}
export const getMyMessageParams = (keyword: string) => {
  const params = {} as myMessageParamType;
  if (keyword) {
    params['keyword'] = keyword;
  }

  return params;
};
export interface coachRevenueClientType {
  serviceStatusFilter: string;
  filter: string;
  page: number;
  size: number;
}
export const getCoachRevenueClientParams = (
  serviceStatusFilter: string,
  filter: string,
  page: number,
  size: number
) => {
  const params = {} as coachRevenueClientType;
  if (serviceStatusFilter) {
    params['serviceStatusFilter'] = serviceStatusFilter;
  }
  if (filter) {
    params['filter'] = filter;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  return params;
};
export interface coachRevenueOverviewType {
  filter: string;
  page: number;
  size: number;
}
export const getCoachRevenueOverviewParams = (
  filter: string,
  page: number,
  size: number
) => {
  const params = {} as coachRevenueOverviewType;
  if (filter) {
    params['filter'] = filter;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  return params;
};
export interface coachReviewType {
  coachServiceFilter: string;
  filter: string;
  page: number;
  size: number;
}
export const getCoachReviewParams = (
  coachServiceFilter: string,
  filter: string,
  page: number,
  size: number
) => {
  const params = {} as coachReviewType;
  if (coachServiceFilter) {
    params['coachServiceFilter'] = coachServiceFilter;
  }

  if (filter) {
    params['filter'] = filter;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  return params;
};
