import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAccessToken } from '../../clientHelpers/localStorage';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { languageSelect } from '../../slice/header';
import './index.css';

const Footer = () => {
  const token = getAccessToken();
  const { pathname } = useLocation();
  const [footerImage, setFooterImage] = useState(-1);

  const authPaths = [
    { page: 'clientLogin', path: '/login' },
    {
      page: 'coachRegistration',
      path: '/coach/registration/personal-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/educational-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/coaching-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/experience-details',
    },
    {
      page: 'coachRegistration',
      path: '/coach/registration/specialization-details',
    },
    { page: 'clientRegistration', path: '/client/registration' },
    { page: 'clientForgotPassword', path: '/forgot-password' },
    { page: 'clientChangePassword', path: '/set-new-password' },
  ];
  const coachSessionPath = [
    { page: 'coachSession', path: '/coach/coach-session' },
    { page: 'createAssessment', path: '/coach/coach-assessment' },
    { page: 'coachPackage', path: '/coach/coach-package' },
  ];
  const filteredSessionPath = coachSessionPath.find(
    (item) => item.path === pathname
  );

  const filteredPath = authPaths.find((item) => item.path === pathname);
  const { lang } = useAppSelector((state) => state.language);
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('common');
  const navigate = useNavigate();
  const handleChange = (value: string) => {
    if (value === 'ar') {
      i18n.changeLanguage('ar');
      if (lang !== value) {
        dispatch(languageSelect(value));
      }

      document.querySelector('html')?.setAttribute('dir', 'rtl');
    } else if (value === 'en') {
      i18n.changeLanguage('en');
      dispatch(languageSelect(value));
      document.querySelector('html')?.setAttribute('dir', 'ltr');
    }
  };

  const company = [
    {
      title: t('LANDING_PAGE_AFTER_LOGIN.ABOUT_US'),
      path: '/about-us',
    },
    {
      title: t('LANDING_PAGE_AFTER_LOGIN.CONTACT_US'),
      path: '/contact-us',
    },
  ];
  const support = [
    {
      title: t('LANDING_PAGE_AFTER_LOGIN.HELP_CENTER'),
      path: 'https://chat.whatsapp.com/FRXgV2Jy8kF4yA85MlqfG0',
      type: 'help',
    },
    {
      title: t('LANDING_PAGE_AFTER_LOGIN.TERMS_OF_SERVICES'),
      path: '/terms-and-condition',
      type: 'link',
    },
    {
      title: t('LANDING_PAGE_AFTER_LOGIN.PRIVACY_POLICY'),
      path: '/privacy-policy',
      type: 'link',
    },
  ];
  const footerImages = [
    {
      path: '/assets/footerAfterLogin/insta.svg',
      pathHover: '/assets/footerAfterLogin/insta-hover.svg',
      route: 'https://www.instagram.com/kun.platform',
    },
    {
      path: '/assets/footerAfterLogin/snapchat.svg',
      pathHover: '/assets/footerAfterLogin/snapchat-hover.svg',
      route:
        'https://www.snapchat.com/add/kun.platform?share_id=B0CEkii2U04&locale=en-SA',
    },
    {
      path: '/assets/footerAfterLogin/twitter.svg',
      pathHover: '/assets/footerAfterLogin/twitter-hover.svg',
      route: 'https://twitter.com/kun_platform',
    },
    {
      path: '/assets/footerAfterLogin/youtube.svg',
      pathHover: '/assets/footerAfterLogin/youtube-hover.svg',
      route: 'https://www.youtube.com/channel/UCtU_p4UFuLFIlmBJg5B_9MQ',
    },
    {
      path: '/assets/footerAfterLogin/linkedin.svg',
      pathHover: '/assets/footerAfterLogin/linkedin-hover.svg',
      route: 'https://www.linkedin.com/company/kunplatform./',
    },
  ];

  useEffect(() => {
    if (lang === 'ar') {
      i18n.changeLanguage('ar');

      dispatch(languageSelect('ar'));

      document.querySelector('html')?.setAttribute('dir', 'rtl');
    } else if (lang === 'en') {
      i18n.changeLanguage('en');
      dispatch(languageSelect('en'));
      document.querySelector('html')?.setAttribute('dir', 'ltr');
    }
  }, [lang]);
  return (
    <>
      {!filteredPath && !filteredSessionPath ? (
        <div className="bg-footerBg text-white w-full h-min-[25rem] h-fit ">
          <div className="py-8 px-5 md:px-20 xl:py-[64px]   flex flex-col lg:flex-row  justify-evenly">
            <div className="flex-grow mb-8">
              <div className="pb-12">
                <img src="/assets/footerAfterLogin/footerIcon.svg" alt="" />
              </div>
              <div className=" text-14 font-normal font-inter rtl:font-Almarai pb-2">
                {t('LANDING_PAGE_AFTER_LOGIN.COPY_RIGHT')}
              </div>
              <div className=" text-14 font-normal font-inter rtl:font-Almarai pb-[40px]">
                {t('LANDING_PAGE_AFTER_LOGIN.ALL_RIGHT_RESERVED')}
              </div>
              <div className="flex flex-row  gap-[30px]">
                {footerImages.map((value: any, index: number) => {
                  return (
                    <a
                      onMouseEnter={() => setFooterImage(index)}
                      onMouseLeave={() => setFooterImage(-1)}
                      href={value.route}
                      target="_blank"
                      key={index}
                      rel="noreferrer"
                    >
                      <div className="w-8 h-8 rounded-[32px] flex items-center justify-center cursor-pointer">
                        <img
                          src={`${
                            footerImage !== index ? value.path : value.pathHover
                          }`}
                          alt=""
                        />
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-between flex-grow">
              <div className="flex flex-col mb-8">
                <div className="text-20 font-medium font-inter rtl:font-Almarai pb-6">
                  {t('LANDING_PAGE_AFTER_LOGIN.COMPANY')}
                </div>
                {company.map((value: any, index: number) => {
                  return (
                    <Link to={value.path} key={index}>
                      <div className="text-16 font-inter rtl:font-Almarai mb-3 cursor-pointer">
                        {value.title}
                      </div>
                    </Link>
                  );
                })}
              </div>
              <div className="flex flex-col mb-8">
                <div className="text-20 font-medium font-inter rtl:font-Almarai mb-6">
                  {t('LANDING_PAGE_AFTER_LOGIN.SUPPORT')}
                </div>
                {support.map((value: any, index: number) => {
                  switch (value.type) {
                    case 'link':
                      return (
                        <Link to={value.path} key={index}>
                          <div className="text-16 font-inter rtl:font-Almarai mb-3 cursor-pointer">
                            {value.title}
                          </div>
                        </Link>
                      );
                    case 'help':
                      return (
                        <div className="text-16 font-inter rtl:font-Almarai mb-3 cursor-pointer">
                          <a
                            href={value.path}
                            target="_blank"
                            rel="noreferrer"
                            // rel="noopener noreferrer"
                          >
                            {value.title}
                          </a>
                        </div>
                      );
                  }
                })}
              </div>
              <div>
                {!token ? (
                  <div className=" text-20 font-medium font-inter rtl:font-Almarai mb-6">
                    <div>{t('ABOUT_US_PAGE.GET_STARTED_NOW')}</div>
                    <div className="flex flex-col gap-4">
                      <Link to="/search-result?page=0&size=9&serviceType=COACHES">
                        <button className="h-[54px] rounded-t bg-primary text-base leading-[21px] border-[1px] border-primary w-full   text-white  mt-7 smmd:w-[295px]">
                          {t('ABOUT_US_PAGE.BROWSE_COACHES')}
                        </button>
                      </Link>

                      <button
                        onClick={() =>
                          navigate('/coach/registration/personal-details')
                        }
                        className="h-[54px] rounded-t bg-transparent text-base leading-[21px] border-[1px] border-white w-full smmd:w-[295px] hover:bg-primary hover:border-transparent hover:text-white"
                      >
                        {t('ABOUT_US_PAGE.BECOME_COACH')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className=" text-20 font-medium font-inter rtl:font-Almarai mb-6">
                    <div className="flex  w-1.6 cursor-pointer ">
                      {lang === 'en' ? (
                        <img
                          src="/assets/headerImage/ar-language.svg"
                          alt="logo"
                          onClick={() => handleChange('ar')}
                        />
                      ) : (
                        <img
                          src="/assets/headerImage/language.svg"
                          alt="ar"
                          onClick={() => handleChange('en')}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {filteredSessionPath ? (
        <div className="h-[4rem] bg-[#212147] text-white w-full flex justify-center items-center font-general rtl:font-Almarai font-normal text-xs">
          {t('HOME.COPYRIGHT_TXT')}
        </div>
      ) : null}
      <div className="h-[4rem] bg-[#212147] text-white w-full flex justify-center items-center font-general rtl:font-Almarai font-normal text-xs">
        build-{process.env.REACT_APP_VERSION}
      </div>
    </>
  );
};

export default Footer;
