import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export interface IPrivacyData {
  data: string;
  key: string;
}
const TermsAndConditions = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();

  const privacyDataAlphabetIndex = [
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_ONE'),
      key: 'A',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_TWO'),
      key: 'B',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_THREE'),
      key: 'C',
    },
    {
      data: t('TERMS_AND_CONDITION.ALPHABETIC_DATA_FOUR'),
      key: 'D',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('KUN')}</title>
        {/* <meta name="description" content="Helmet application" /> */}
      </Helmet>

        <div className="text-justify sm:mx-20 sm:mb-20 sm:mt-8 m-4" lang={'ar'} dir={'rtl'}>
            <div
                onClick={() => navigate(-1)}
                className="bg-primary h-[45px] w-[45px] rounded flex items-center justify-center  pl-2  mb-8 rtl:rotate-180"
            >
                <ArrowBackIosIcon sx={{color: '#ffff'}}/>
            </div>
            <h2 className="font-satoshi rtl:font-Almarai  font-bold text-3xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.MAIN_HEADING',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.INTRODUCTION',{
                    lng: 'ar',
                })}
            </h2>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.INTRODUCTION_DESC',{
                    lng: 'ar',
                })}
            </p>
            <h2 className=" rtl:font-Almarai font-bold text-3xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_INTRODUCTION',{
                    lng: 'ar',
                })}
            </p>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_INTRODUCTION',{
                    lng: 'ar',
                })}
            </p>
            <br/>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_1',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_2',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_3',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_4',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_5',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_6',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_7',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_8',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_9',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_10',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_11',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_12',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_13',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_14',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_15',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_16',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIRST_POINT_17',{
                        lng: 'ar',
                    })}
                </li>
            </ol>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.CONDITIONS_POLICY',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.CONDITIONS_POLICY_INTRODUCTION',{
                    lng: 'ar',
                })}
            </p>
            <br/>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_INTRODUCTION',{
                    lng: 'ar',
                })}
            </p>
            <br/>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_1',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_2',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_3',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p><span>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4',{
                        lng: 'ar',
                    })}</span>
                        <br/>
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_4_2',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_5',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6',{
                        lng: 'ar',
                    })}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_3',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_6_4',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_7',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_8',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_9',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_10',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SECOND_FIRST_11',{
                        lng: 'ar',
                    })}
                </li>

            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE',{
                    lng: 'ar',
                })}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1',{
                        lng: 'ar',
                    })}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_3',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_1_4',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2',{
                        lng: 'ar',
                    })}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_3',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_4',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_5',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_6',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_7',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_8',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_9',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_10',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_11',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_12',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_13',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_14',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_15',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_THREE_2_16',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR',{
                    lng: 'ar',
                })}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1',{
                        lng: 'ar',
                    })}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_3',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_4',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_1_5',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    <p>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2',{
                        lng: 'ar',
                    })}
                        <ul className={"rtl:mr-2  list-disc rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_3',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_4',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_5',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_6',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_7',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_8',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_9',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_10',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_11',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_12',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_13',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_14',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_15',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FOUR_2_16',{
                                lng: 'ar',
                            })}</li>
                        </ul>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION',{
                    lng: 'ar',
                })}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li>
                    <p>
                        <span
                            className={"text-lg"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST',{
                            lng: 'ar',
                        })}</span>
                        <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_FIRST_3',{
                                lng: 'ar',
                            })}</li>
                        </ol>
                    </p>
                </li>
                <li>
                    <p><span
                        className={"text-lg"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND',{
                        lng: 'ar',
                    })}</span>
                        <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai mt-4"}>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_1',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_2',{
                                lng: 'ar',
                            })}</li>
                            <li className={"mt-2"}>{t('TERMS_AND_CONDITION.PAYMENT_REFUND_AND_COMPENSATION_SECOND_3',{
                                lng: 'ar',
                            })}</li>
                        </ol>
                    </p>
                </li>
            </ol>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE',{
                    lng: 'ar',
                })}
            </h2>
            <br/>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_INTRODUCTION',{
                    lng: 'ar',
                })}
            </p>
            <p className=" rtl:font-Almarai ">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS',{
                    lng: 'ar',
                })}
            </p>
            <ol className={"rtl:mr-2  list-disc rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_1',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_2',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_3',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_4',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_5',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_6',{
                        lng: 'ar',
                    })}
                </li>
            </ol>
            <p className=" rtl:font-Almarai text-lg">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_FIVE_TERMS_FINALIZE',{
                    lng: 'ar',
                })}
            </p>
            <h2 className=" rtl:font-Almarai font-bold text-2xl text-left rtl:text-right">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX',{
                    lng: 'ar',
                })}
            </h2>
            <ol className={"rtl:mr-2  list-decimal rtl:font-Almarai"}>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_1',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_2',{
                        lng: 'ar',
                    })}
                </li>
                <li className="rtl:p-1 rtl:font-Almarai ">
                    {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY_SUBJECT_SIX_3',{
                        lng: 'ar',
                    })}
                </li>
            </ol>
            <p className=" rtl:font-Almarai text-lg">
                {t('TERMS_AND_CONDITION.TERMS_AND_CONDITIONS_POLICY__FINALIZE',{
                    lng: 'ar',
                })}
            </p>
            {/*  <p className="rtl:font-Almarai ">*/}
            {/*<span className="font-satoshi rtl:font-Almarai font-bold">*/}
            {/*  {t('TERMS_AND_CONDITION.SUB_HEADING')}:*/}
            {/*</span>{' '}*/}
            {/*      {t('TERMS_AND_CONDITION.SUB_DESCRIPTION')}*/}
            {/*  </p>*/}
            {/*  <br/>*/}
            {/*  <p className="font-satoshi rtl:font-Almarai font-bold">*/}
            {/*      1) {t('TERMS_AND_CONDITION.NUMBERIC_DATA_ONE')}*/}
            {/*  </p>*/}
            {/*  <br/>*/}
            {/*  <>*/}
            {/*      {privacyDataAlphabetIndex.map((item, index) => {*/}
            {/*          return (*/}
            {/*              <>*/}
            {/*                  <p key={index} className="rtl:font-Almarai ">*/}
            {/*                      {item.key} . {item.data}*/}
            {/*                  </p>*/}
            {/*                  <br/>*/}
            {/*              </>*/}
            {/*          );*/}
            {/*      })}*/}
            {/*  </>*/}
        </div>
    </>
  );
};

export default TermsAndConditions;
